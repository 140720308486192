import styled from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

export const PrimaryHeader = styled.h2`
  font-size: ${props => props.theme.lineHeight.xxxl};
  margin: ${getDimension('size4')} ${getDimension('size2')};

  font-weight: 400;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${props => props.theme.lineHeight.xxl};
  }
`;

export const SecondaryHeader = styled.h2`
  margin: 0px;
  font-weight: 400;
`;
