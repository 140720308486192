import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import { Box } from 'components/Box';
import { FlexContainer } from 'components/FlexContainer';
import { Container, StatContainer } from './Container.styled';
import { PrimaryHeader, SecondaryHeader } from './Header.styled';
import { SubText } from './SubText.styled';
import { Bar } from './Bar.styled';

import type { FC } from 'react';

export const StatsBlock: FC = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));

  return (
    <Box padding={isMobile ? 12 : 0} background="navyBlue">
      <Container>
        <StatContainer>
          <FlexContainer justifyContent="center" alignItems="flex-end">
            <PrimaryHeader>300</PrimaryHeader>
            <SecondaryHeader>k+</SecondaryHeader>
          </FlexContainer>
          <Bar backgroundColor="yellow" />
          <SubText>Investors trust Sharesight</SubText>
        </StatContainer>
        <StatContainer>
          <FlexContainer justifyContent="center" alignItems="flex-end">
            <PrimaryHeader>250</PrimaryHeader>
            <SecondaryHeader>k+</SecondaryHeader>
          </FlexContainer>
          <Bar backgroundColor="primary" />
          <SubText>Stocks, ETFs & funds supported</SubText>
        </StatContainer>
        <StatContainer>
          <FlexContainer justifyContent="center" alignItems="flex-end">
            <PrimaryHeader>200</PrimaryHeader>
          </FlexContainer>
          <Bar backgroundColor="orange" />
          <SubText>Software, broker & partner integrations</SubText>
        </StatContainer>
      </Container>
    </Box>
  );
};
