import styled from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

export const SubText = styled.div`
  text-align: center;
  margin: ${getDimension('size4')} auto 0 auto;

  font-size: ${props => props.theme.fontSize.md};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${getDimension('size1')} auto 0 auto;
  }
`;
