import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

import type { Color } from 'styled/types';

export const Bar = styled.div<{ backgroundColor: Color }>`
  height: ${getDimension('size3')};
  width: 100%;
  background-color: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
`;
