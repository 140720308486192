import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: center;
  }
`;

const statContainerClassname = 'stats-block-stat-container';

export const StatContainer = styled.div.attrs(() => ({
  className: statContainerClassname,
}))`
  display: flex;
  flex-direction: column;
  width: 20%;

  .${statContainerClassname} + & {
    margin-left: 10%;

    ${({ theme }) => theme.breakpoints.down('md')} {
      margin-left: 0;
      margin-top: 15%;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 65%;
  }

  color: ${({ theme }) => theme.colors.white};
`;
